import { ApiClientOnlineSchoolCtpStatementAcademicYears } from 'ApiRest/Api/Account/Client/StatementOnlineSchool/AcademicYears'
import { ApiClientOnlineSchoolCtpStatement } from 'ApiRest/Api/Account/Client/StatementOnlineSchool/Statement'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchStatements({ commit, state, rootGetters }) {
    const { data } = await ApiClientOnlineSchoolCtpStatement.get(
      rootGetters['client/students/currentStudentId'],
      {
        academicYearPreset: {
          id: state.academicYear.id,
        },
      },
    )

    commit('setStatements', data)
  },

  async fetchAcademicYears({ commit, rootGetters }) {
    const { data } = await ApiClientOnlineSchoolCtpStatementAcademicYears.get(
      rootGetters['client/students/currentStudentId'],
    )

    commit('setAcademicYears', data)
  },
}
